import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { AppNavBar, NavItemT } from 'baseui/app-nav-bar';
import { StyledLink } from "baseui/link";
import { Block } from 'baseui/block';
import { Spinner } from 'baseui/spinner';
import useAuthStore from '@/store/auth';
import { usePost } from '@/hooks/apiHooks';

const Header = () => {
  const [css, theme] = useStyletron();
  const logout = usePost("agency-api/logout");
  const logOut = useAuthStore(state => state.logout);
  const authData = useAuthStore(state => state.authData)
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [activePath, setActivePath] = useState(window.location.hash);
  const navigate = useNavigate();

  useEffect(() => {
    // Override pushState and replaceState to trigger custom event
    setActivePath(window.location.pathname);
  }, [window.location.pathname]);


  const navItems: NavItemT[] = [
    { label: 'Jobs', info: { href: "/jobs" } },
    { label: 'Candidates', info: { href: "/candidates" } },
    { label: 'Bulk uploads', info: { href: "/list-bulk-candidates" } },
  ];

  const handleLogout = () => {
    setLogoutLoading(true);
    logout.mutate({}, {
      onSuccess: () => {
        logOut();
        setLogoutLoading(false);
      },
      onError: () => {
        logOut();
        setLogoutLoading(false);
      }
    });
  };

  return (
    <AppNavBar
      username={authData.userName}
      usernameSubtitle={authData.agencyName}
      title={
        <Block display="flex" onClick={() => navigate("/")} style={{ cursor: 'pointer' }}>
          <img
            src="/turing-logo.png"
            alt="Turing"
            className={css({
              height: '24px',
              marginRight: theme.sizing.scale200,
              [theme.mediaQuery.small]: {
                height: '20px',
              },
            })}
          />
        </Block>
      }
      mainItems={navItems.map((item) => ({
        label: item.label,
        active: activePath === item.info.href,
        onClick: () => {
          window.location.href = item.info.href;
          navigate(item.info.href)
        },
        info: item.info,
      }))}
      onMainItemSelect={(item) => {
        setActivePath(item.info.href);
        navigate(item.info.href);
      }}
      userItems={[
        {
          label: logoutLoading ? (
            <Spinner size="10px" overrides={{ Svg: { style: { width: '10px', height: '10px' } } }} />
          ) as unknown as string : (
            <Block onClick={handleLogout} padding="0" width="100%">
                  <StyledLink
                  className={css({
                    fontSize: theme.typography.font400.fontSize,
                  })}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                
                >
                  Logout
                </StyledLink>
            </Block>
          ) as unknown as string
        },
      ]}
      overrides={{
        Root: {
          style: {
            backgroundColor: theme.colors.primaryB,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            zIndex: 10,
            margin: 0,
            maxWidth: '100%'
          },
        },
      }}
    />
  );
};

export default Header;
