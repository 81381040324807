import { useQuery, useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { fetcher } from '@/network/api/fetcher';
import { ApiResponse } from '@/network/definitions/paginated-response';

type QueryParams = Record<string, string | number | boolean>;

export const useGet = <T>(url: string, params?: QueryParams): UseQueryResult<ApiResponse<T>, Error> => {
    return useQuery<ApiResponse<T>, Error>({
        queryKey: ['get', { url, ...params }],
        queryFn: () => fetcher<ApiResponse<T>>({ queryKey: ['get', { url, ...params }] }),
    });
};

export const usePost = <T, R = void>(url: string) => {
    const queryClient = useQueryClient();
    return useMutation<R, Error, T | FormData>(
        {
            mutationFn: (body: T | FormData) => {
                const headers = body instanceof FormData
                    ? {}
                    : { 'Content-Type': 'application/json' };

                return fetcher<R>({
                    queryKey: ['post', { url }],
                    method: 'POST',
                    // @ts-ignore
                    body,
                    headers,
                });
            },
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['get', { url }] });
            },
        }
    );
};

export const usePut = <T, R = void>(url: string) => {
    const queryClient = useQueryClient();
    return useMutation<R, Error, T>(
        {
            mutationFn: (body: any) => fetcher<R>({ queryKey: ['put', { url }], method: 'PUT', body }),
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['get', { url }] });
            },
        }
    );
};


export const usePatch = <T, R = void>(url: string) => {
    const queryClient = useQueryClient();
    return useMutation<R, Error, T>(
        {
            mutationFn: (body: any) => fetcher<R>({ queryKey: ['patch', { url }], method: 'PATCH', body }),
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['get', { url }] });
            },
        }
    );
};

export const useDelete = <T>(url: string) => {
    const queryClient = useQueryClient();
    return useMutation<T, Error>(
        {
            mutationFn: () => fetcher<T>({ queryKey: ['delete', { url }], method: 'DELETE' }),
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['get', { url }] });
            },
        }
    );
};


export const useExport = (url: string) => {
    return useMutation<void, Error>({
        mutationFn: async () => {
            const exportUrl = `${url}&export=true`;

            // Use fetcher with rawResponse to get the raw Response object
            const response = await fetcher<Response>({
                queryKey: ['export', { url: exportUrl }],
                method: 'GET',
                rawResponse: true,
            });

            if (!response.ok) {
                throw new Error('Failed to export CSV');
            }

            const blob = await response.blob();
            const contentDisposition = response.headers.get('Content-Disposition');
            const filename =
                contentDisposition?.match(/filename="([^"]+)"/)?.[1] || 'export.csv';

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();

            window.URL.revokeObjectURL(link.href); // Clean up the URL
        },
    });
};