// PrivateRoute component
import { Navigate } from 'react-router-dom';
import { Block } from 'baseui/block';
import { ToasterContainer } from 'baseui/toast';
import Header from './Header';
import useAuthStore from '../store/auth';

interface PrivateRouteProps {
  element: React.ReactNode; // The element to render when authenticated
}

const PrivateRoute = ({ element }: PrivateRouteProps) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  return isAuthenticated() ? (
    <>
      <Header />
      <ToasterContainer
        overrides={{
          Root: {
            style: {
              zIndex: 9999, // Adjust this as needed
            },
          },
        }} />
      <Block style={{
        margin: '20px',
      }}>
        {element}
      </Block>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;