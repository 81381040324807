import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'baseui/card';
import { Grid, Cell } from 'baseui/layout-grid';
import { Button } from 'baseui/button';
import { HeadingSmall, HeadingXSmall } from 'baseui/typography';
import Joi from 'joi';
import { toaster } from 'baseui/toast';
import Papa from 'papaparse'; // CSV parser
import { usePost } from '@/hooks/apiHooks';
import { FileUploader } from 'baseui/file-uploader';
import { FormControl } from 'baseui/form-control';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { Spinner } from 'baseui/spinner';
import { FlexGrid } from 'baseui/flex-grid';
import { DeleteAlt } from 'baseui/icon';

const MAX_ROWS_LIMIT = 100;

const candidateSchema = Joi.object({
  name: Joi.string().trim().required().messages({
    'string.empty': 'Name is required',
  }),
  email: Joi.string().trim().email({ tlds: { allow: false } }).required().messages({
    'string.empty': 'Email is required',
    'string.email': 'Invalid email format',
  }),
  phone: Joi.string().trim()
    .pattern(/^\+?[1-9]\d{1,14}$/)
    .required()
    .messages({
      'string.pattern.base': 'Invalid phone number format',
    }),
  country: Joi.string().trim().required().messages({
    'string.empty': 'Country is required',
  }),
  expected_rate: Joi.number().required().messages({
    'number.base': 'Expected salary is required',
  }),
  job_id: Joi.number().positive().integer().required().messages({
    'number.empty': 'Job id is required',
    'number.base': 'Job id is should be number',
  }),
  availability_weeks: Joi.number().required().messages({
    'number.base': 'Availability weeks is required',
  }),
  years_of_experience: Joi.number().required().messages({
    'number.base': 'Years of experience is required',
  }),
  notes: Joi.string().trim().required().messages({
    'string.empty': 'Notes are required',
  }),
  resume_link: Joi.string().trim()
    .pattern(/^https?:\/\/[^\s/$.?#].[^\s]*$/) // Allows most valid URLs with special characters
    .required()
    .messages({
      'string.empty': 'Resume link is required',
      'string.pattern.base': 'Invalid resume link format',
    }),
  is_fulltime: Joi.alternatives()
    .try(
      Joi.boolean(),
      Joi.string().trim().valid("yes", "no", " Yes", "No"),
      Joi.allow(null)
    )
    .optional()
    .messages({
      'boolean.base': 'Should be either yes or no',
      'any.only': 'Should be either yes or no'
    })
    .custom((value) => {
      if (value === undefined || value === null || value == '') return value;
      if (value.toLowerCase() === "yes") return true;
      if (value.toLowerCase() === "no") return false;
      return value;
    }),
});

export type BulkUploadErrorMessage = {
  rowNumber: number;
  message: string;
}

const ApplyCandidateFormBulk: React.FC = () => {
  const navigate = useNavigate();
  const [csvData, setCsvData] = useState<any[]>([]);
  const [cellErrors, setCellErrors] = useState<Record<string, string>>({});
  const [apiErrors, setApiErrors] = useState<BulkUploadErrorMessage[]>([]);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csv, setCsv] = useState<File | null>(null)
  const submitMutation = usePost(`agency-dev-applications/import-candidate-bulk?randomToken=true`);

  const validateCsvRow = (candidate: any, rowIndex: number, seenEmails: Set<string>): Record<string, string> => {
    const { error } = candidateSchema.validate(candidate, { abortEarly: false });
    const rowErrors: Record<string, string> = {};

    if (error) {
      error.details.forEach((detail) => {
        const key = detail.path[0];
        rowErrors[key] = detail.message;
        setCellErrors((prev) => ({
          ...prev,
          [`${rowIndex}-${key}`]: detail.message,
        }));
      });
    }

    if (seenEmails.has(candidate.email)) {
      const duplicateMessage = "Duplicate email detected within the CSV file";
      rowErrors.email = duplicateMessage;
      setCellErrors((prev) => ({
        ...prev,
        [`${rowIndex}-email`]: duplicateMessage,
      }));
    } else {
      seenEmails.add(candidate.email); // Add email to seen set
    }

    return rowErrors;
  };

  const handleCsvFile = (file: File) => {
    setLoading(true);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const candidates = results.data.map((candidate: any) => ({
          ...candidate,
        }));
        if (candidates.length > MAX_ROWS_LIMIT) {
          toaster.negative(`CSV file contains more than ${MAX_ROWS_LIMIT} rows, only ${MAX_ROWS_LIMIT} rows are allowed per upload`);
          setLoading(false);
          setHasValidationErrors(true);
          return;
        }

        setCsv(file);
        const newCellErrors: Record<string, string> = {};
        const seenEmails = new Set<string>();

        candidates.forEach((candidate: any, rowIndex: number) => {
          const rowErrors = validateCsvRow(candidate, rowIndex, seenEmails);
          candidate.originalIndex = rowIndex
          Object.keys(rowErrors).forEach((key) => {
            newCellErrors[`${rowIndex}-${key}`] = rowErrors[key];
          });
        });

        setCsvData(candidates);
        setCellErrors(newCellErrors);
        setLoading(false);

        if (Object.keys(newCellErrors).length > 0) {
          toaster.negative('CSV contains errors. Please resolve before submitting.', {});
        } else {
          setHasValidationErrors(false);
        }
      },
      error: () => {
        setLoading(false);
        toaster.negative('Failed to parse CSV.', {});
      },
    });
  };

  const cancelFile = () => {
    setCsvData([]);
    setCsv(null);
  };


  const handleCsvSubmit = async () => {
    if (Object.keys(cellErrors).length > 0) {
      toaster.negative('Please resolve all errors before submitting.', {});
      return;
    }


    const formPayload = new FormData();
    if (csv)
      formPayload.append('agency-candidates', csv);


    try {
      await submitMutation.mutateAsync(formPayload, {
        onSuccess: (res: any) => {
          if (res.success) {
            toaster.positive(`Items added for bulk upload.`, {});
            navigate('/list-bulk-candidates')
          } else {
            setApiErrors(res.errors);
            toaster.negative('Erorr processing csv, please fix the issues and try again.');
          }
        },
        onError: () => {
          toaster.negative(`Failed to add`, {});
        },
      });
    } catch (error) {
      toaster.negative(`Error submitting.`, {});
    }
  };

  return (
    <>
      <FlexGrid
        flexGridColumnCount={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* <NavigateBack route={`/`} text={`Go back to jobs`} /> */}
        <Button
          style={{ marginBottom: '10px' }}
          onClick={() => { navigate('/list-bulk-candidates') }}
        >View Upload Status</Button>
      </FlexGrid>
      <Card
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors.backgroundSecondary, // Customize the color
              padding: $theme.sizing.scale100,
              minWidth: "100%",
              width: "100%",
            }),
          }
        }}
      >
        <Grid>
          <Cell span={12}>
            <HeadingSmall style={{ textAlign: 'center', marginBottom: '20px' }}>
              Bulk Upload Candidates
            </HeadingSmall>
          </Cell>

          <Cell span={12}>
            <FormControl
              label="Upload CSV File"
              caption={
                <ul style={{ margin: 0, paddingLeft: '1.25rem' }}>
                  <li>Only CSV files are allowed.</li>
                  <li>Ensure each row includes a resume link.</li>
                  <li>Max rows per upload: {MAX_ROWS_LIMIT}.</li>
                  <li>For more details look at the <a href="https://docs.google.com/spreadsheets/d/18b_O0y7t9OhOiJlhLqozmpzR2LJtStJxPxWk7-2-DRc/edit?gid=0#gid=0" target="_blank" rel="noopener noreferrer">template</a></li>
                </ul>
              }
            >
              <FileUploader
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length > 0) {
                    const file = acceptedFiles[0];
                    handleCsvFile(file);
                  }
                }}
                onDropRejected={cancelFile} // Reset file data on rejected drop
                accept=".csv"
                multiple={false}
                name="csv"
                onRetry={cancelFile} // Allows resetting the uploader
                onCancel={cancelFile} // Clears the upload
                overrides={{
                  ContentMessage: {
                    component: () => (
                      csv ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{`${csv.name}`}</span>
                          <DeleteAlt
                            size={24}
                            onClick={cancelFile} // Clear the uploaded file
                            style={{ cursor: 'pointer', marginLeft: '1rem' }}
                          />
                        </div>
                      ) : (
                        <div>Drop files here to upload...</div>
                      )
                    ),
                  },
                  ButtonComponent: {
                    props: {
                      overrides: {
                        BaseButton: {
                          style: {
                            display: csv ? 'none' : 'block',
                          },
                        },
                      }
                    }
                  },
                }}
              />
            </FormControl>
          </Cell>

          {loading && (
            <Cell span={12}>
              <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                <Spinner size={50} />
              </div>
            </Cell>)}

          {!loading && (
            <Cell span={12}>
              <Button
                disabled={submitMutation.isPending || csvData.length === 0 || csv === null || Object.keys(cellErrors).length > 0 || hasValidationErrors}
                onClick={handleCsvSubmit}
                isLoading={submitMutation.isPending}
                overrides={{
                  BaseButton: {
                    style: {
                      backgroundColor: '#276EF1',
                      color: '#fff',
                      fontWeight: 'bold',
                    },
                  },
                  Root: {
                    style: {
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '20px'
                    },
                  }
                }}
              >
                Upload CSV and Submit
              </Button>
            </Cell>
          )}

          {Object.keys(cellErrors).length > 0 && (
            <Card
              overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    backgroundColor: $theme.colors.backgroundSecondary, // Customize the color
                    padding: $theme.sizing.scale100,
                    minWidth: "100%",
                    width: "100%",
                    marginTop: '40px'
                  }),
                }
              }}
            >
              <>
                <HeadingXSmall>Error Validating CSV, please fix the following errors:</HeadingXSmall>
                <TableBuilder data={csvData.filter((_, rowIndex) => {
                  return Object.keys(cellErrors).some((key) => key.startsWith(`${rowIndex}-`));
                })}>
                  <TableBuilderColumn header="Row Number">
                    {(row: any) => {
                      return row['originalIndex'] + 2;
                    }}
                  </TableBuilderColumn>
                  {Object.keys(csvData[0] || {}).map((columnKey) => (
                    <TableBuilderColumn key={columnKey} header={columnKey.replace(/_/g, ' ').toUpperCase()}>
                      {(row: any) => {
                        const errorKey = `${row['originalIndex']}-${columnKey}`;
                        return (
                          <div>
                            <span>{row[columnKey]}</span>
                            {cellErrors[errorKey] && (
                              <span style={{ color: 'red' }}> ({cellErrors[errorKey]})</span>
                            )}
                          </div>
                        );
                      }}
                    </TableBuilderColumn>
                  ))}
                </TableBuilder>
              </>
            </Card>
          )}

          {apiErrors.length > 0 && (
            <Card
              overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    backgroundColor: $theme.colors.backgroundSecondary,
                    padding: $theme.sizing.scale100,
                    minWidth: "100%",
                    width: "100%",
                    marginTop: '40px'
                  }),
                }
              }}
            >
              <>
                <HeadingXSmall>Processing Errors:</HeadingXSmall>
                <TableBuilder data={apiErrors}>
                  <TableBuilderColumn header="Row Number">
                    {(error: BulkUploadErrorMessage) => error.rowNumber + 1}
                  </TableBuilderColumn>
                  <TableBuilderColumn header="Error Message">
                    {(error: BulkUploadErrorMessage) => (
                      <div style={{ color: 'red' }}>{error.message}</div>
                    )}
                  </TableBuilderColumn>
                </TableBuilder>
              </>
            </Card>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default ApplyCandidateFormBulk;