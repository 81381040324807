export interface Job {
    id: number;
    role: string;
    active: boolean;
    agencyType: string;
    agenciesJobDescriptionLink: string;
}

export interface Vetsmith {
    completedDate: Date;
    createdDate: Date;
    enabled: boolean;
    flowId: number;
    id: number;
    status: string;
    updatedDate: Date;
}

export interface CandidateData {
    agency_dev_id: number;
    application_review_status: string;
    email: string;
    final_developer_rate: string;
    job_title: string;
    job_id: number;
    name: string;
    onboarding_status: string;
    trial_start_date: string;
    uploaded_date: string;
    mti_status: string;
    notice_period: number;
    location: string;
    note: string;
    expected_rate: string;
    reviewer_comments: string;
    vetsmith: Vetsmith;
    job_request_status: string | null;
}

export interface PagionationData {
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    total: number
}

export enum DeveloperType {
    FTE = 'fte',
    CONTRACTOR = 'contractor',
    SUB_CONTRACTOR = 'sub_contractor',
}