import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card } from 'baseui/card';
import { Grid, Cell } from 'baseui/layout-grid';
import { Value } from 'baseui/select';
import { Button } from 'baseui/button';
import { HeadingSmall } from 'baseui/typography';
import { toaster } from 'baseui/toast';
import { useGet, usePost } from '@/hooks/apiHooks';
import useJobStore from '@/store/jobs';
import { COUNTRIES, Country } from 'baseui/phone-input';
import ResumeUpload from './resume-upload';
import FormControls, { FormData as FormDataFields } from './form-controls';
import { JobCard } from '../jobs/job';
import { NavigateBack } from '../navigation/navigate-back';
import { DeveloperType } from '@/network/definitions/job';

const ApplyCandidateForm: React.FC = () => {
  const { id: jobId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [resume, setResume] = useState<File | null>(null);
  const [currency, setCurrency] = useState('INR');
  const [frequency, setFrequency] = useState('monthly');
  const [phoneCountry, setPhoneCountry] = React.useState(
    COUNTRIES.US
  );
  const [formData, setFormData] = useState<FormDataFields>({
    name: '',
    email: '',
    phone: '',
    countryId: [],
    city: '',
    developerType: [],
    salaryCurrency: currency,
    salaryFrequency: frequency,
    expectedSalary: '',
    yearsOfExperience: '',
    noticePeriod: '2',
    note: '',
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  const getJobById = useJobStore((state) => state.getJobById);
  const job = getJobById(Number(jobId));
  const { data: { data: countries = [] } = {} } = useGet<Country>('agency-api/countries');
  const submitMutation = usePost<FormData, unknown>(`agency-api/job/${jobId}/apply-candidate`);
  const [engagementTypes, setEngagementTypes] = useState([
    { label: 'Full-time Employee', id: 'fte' },
    { label: 'Contractor', id: 'contractor' },
    { label: 'Sub-contractor', id: 'sub_contractor' }, // Leave as default in case of API failure
  ])

  useEffect(() => {
    if (job?.agencyType) {
      setEngagementTypes(job.agencyType === 'subcontractor' ?
        [{ label: 'Sub-contractor', id: DeveloperType.SUB_CONTRACTOR }]
        : [
          { label: 'Full-time Employee', id: DeveloperType.FTE },
          { label: 'Contractor', id: DeveloperType.CONTRACTOR }
        ])
    }
  }, [job])

  useEffect(() => {
    if (!formData.developerType.length) {
      if (job?.agencyType === 'subcontractor')
        setFormData({ ...formData, developerType: [{ id: DeveloperType.SUB_CONTRACTOR }] })
      else
        setFormData({ ...formData, developerType: [{ id: DeveloperType.CONTRACTOR }] });
    } else {
      handleRoleChange(String(formData.developerType[0].id));
    }
    updateCountryCurrency()
  }, [formData.developerType, formData.countryId]);

  const isValidEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = (): boolean => {
    const phoneNumberWithoutDialCode = formData.phone.replace(phoneCountry.dialCode, '').replace(/\D/g, '');

    const newErrors: Record<string, string> = {};
    if (!resume) newErrors.resume = 'Resume is required';
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (formData.email && !isValidEmail(formData.email)) newErrors.email = 'Please enter a valid email address.';
    if (!formData.phone) newErrors.phone = 'Phone number is required';
    if (!formData.yearsOfExperience) newErrors.yearsOfExperience = "Years of experience is required";
    if (!formData.countryId.length) newErrors.countryId = 'Country is required';
    if (!formData.developerType.length) newErrors.developerType = 'Developer type is required';
    if (!formData.expectedSalary) newErrors.expectedSalary = 'Expected salary is required';
    if (phoneNumberWithoutDialCode.length === 0) newErrors.phone = 'Phone number is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updateCountryCurrency = () => {
    const countryData = formData.countryId;
    let defaultCurrency = String(formData?.developerType?.[0]?.id) === 'fte' ? 'INR' : 'USD'
    const currency = countryData?.[0]?.currency || defaultCurrency;
    // Only update currency for FTE developers, else leave as current
    if (currency && String(formData?.developerType?.[0]?.id) === 'fte') {
      setCurrency(currency);
      setFormData({ ...formData, salaryCurrency: currency, salaryFrequency: 'annually' });
    }
  }

  const handleSelectChange = (name: keyof FormData, value: Value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleRoleChange = (role: string) => {
    let currencyToSelect = currency, frequencyToSelect = frequency;
    if (role !== 'fte') {
      currencyToSelect = 'USD';
      frequencyToSelect = 'hourly';
      setCurrency(currencyToSelect);
    } else {
      frequencyToSelect = 'annually';
      updateCountryCurrency();
    }
    setFrequency(frequencyToSelect);
    setFormData({ ...formData, salaryCurrency: currencyToSelect, salaryFrequency: frequencyToSelect });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const formPayload = new FormData();

      Object.keys(formData).forEach((key) => {
        const value = formData[key as keyof FormData];
        if (key === 'countryId' || key === 'developerType') {
          formPayload.append(key, (value[0] as any).id);
        } else {
          formPayload.append(key, String(value));
        }
      });
      if (resume) {
        formPayload.append('resume', resume);
      }

      submitMutation.mutate(formPayload, {
        onSuccess: (res: any) => {
          if (res && res.statusCode && ![200, 201].includes(res.statusCode)) {
            if (res.message.message) {
              if (Array.isArray(res.message.message)) {
                toaster.negative(res.message.message[0], {});
                return;
              } else {
                toaster.negative(res.message.message, {});
                return;
              }
            } else {
              toaster.negative(res.message, {});
              return;
            }
          }
          toaster.positive('Candidate Added Successfully!', {
            autoHideDuration: 3000
          },);
          navigate(`/job/${jobId}`);
        },
        onError: () => {
          toaster.negative('Failed to create candidate', {});
        },
      });
    }
  };

  if (!job) return null;

  return (
    <>
      <NavigateBack
        route={`/job/${jobId}`}
        text={`Go back to job`}
      />
      <JobCard job={job} actionComponent={<span />} />
      <Card
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors.backgroundSecondary, // Customize the color
              padding: $theme.sizing.scale100,
              minWidth: "100%",
              width: "100%",
            }),
          },
        }}
      >
        <Grid>
          <Cell span={12}>
            <HeadingSmall>Add a candidate</HeadingSmall>
          </Cell>
          <ResumeUpload setResume={setResume} errors={errors?.resume || ""} />
          <FormControls
            formData={formData}
            countries={countries}
            engagementTypes={engagementTypes}
            phoneCountry={phoneCountry}
            // @ts-ignore 
            setPhoneCountry={setPhoneCountry}
            errors={errors}
            handleChange={handleChange}
            // @ts-ignore 
            handleSelectChange={handleSelectChange}
            currency={currency}
          />
          <Cell span={12}>
            <Button onClick={handleSubmit} isLoading={submitMutation.isPending}>Add candidate</Button>
          </Cell>
        </Grid>
      </Card>
    </>
  );
};

export default ApplyCandidateForm;