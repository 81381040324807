import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'baseui/table-semantic';
import { Pagination } from 'baseui/pagination';
import { Input } from 'baseui/input';
import { Select } from 'baseui/select';
import { Button } from 'baseui/button';
import { Spinner } from 'baseui/spinner';
import { Block } from 'baseui/block';
import { useGet, useExport } from '@/hooks/apiHooks';
import { useDebounce } from '@/hooks/useDebounce';
import { ApiResponse } from '@/network/definitions/paginated-response';

export enum FilterTypes {
    Text = 'text',
    Select = 'select',
}

type TableState = {
    rowData: any[];
    totalRecords: number;
    pageNumber: number;
    pageSize: number;
    filters: Record<string, string>;
    pinnedColumns: ColumnConfig[];
    scrollableColumns: ColumnConfig[];
    draggedColumn: ColumnConfig | null;
    pinnedWidth: number;
    sortConfig: { sortField: string | null, sortOrder: string | null }
}

type ColumnConfig = {
    field: string;
    headerName: string;
    filterType?: FilterTypes;
    filterCustomName?: string;
    filterValues?: { value: string | number; label: string }[];
    pinned?: boolean;
    render?: (row: any) => JSX.Element | string | number;
};

type Props = {
    apiEndpoint: string;
    initialColumnsConfig: ColumnConfig[];
    defaultFilters?: Record<string, string>;
    loading?: boolean;
};

const PAGE_SIZES = [10, 20, 50];

const buildQueryString = (params: Record<string, any>): string =>
    Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

const CenteredDisplay: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Block display="flex" width="100%" alignItems="center" justifyContent="center">
        {children}
    </Block>
);

const Filters: React.FC<{
    columns: ColumnConfig[];
    filters: Record<string, string>;
    onFilterChange: (key: string, value: string, type?: FilterTypes) => void;
    onExportCsv: () => void;
}> = React.memo(({ columns, filters, onFilterChange, onExportCsv }) => {
    const handleInputChange = useCallback((field: string, value: string, type?: FilterTypes) => {
        onFilterChange(field, value, type);
    }, [onFilterChange]);
    return (
        <Block display="flex" gridGap="12px" marginBottom="16px" >
            {columns.map(
                (col) =>
                    col.filterType && (
                        <Block key={col.field} flex="1" minWidth="150px">
                            {col.filterType === FilterTypes.Text && (
                                <Input
                                    clearable
                                    value={filters[col.filterCustomName || col.field]}
                                    size="compact"
                                    placeholder={col.headerName}
                                    onChange={(e) => handleInputChange(col.field, e.target.value, FilterTypes.Text)}
                                />
                            )}
                            {col.filterType === FilterTypes.Select && (
                                <Select
                                    size="compact"
                                    options={col.filterValues?.map((item) => ({
                                        id: item.value,
                                        label: item.label,
                                    }))}
                                    placeholder={col.headerName}
                                    value={
                                        filters[col.filterCustomName || col.field]
                                            ? [
                                                {
                                                    id: filters[col.filterCustomName || col.field],
                                                    label: col.filterValues?.find((item) => item.value === filters[col.filterCustomName || col.field])
                                                        ?.label,
                                                },
                                            ]
                                            : []
                                    }
                                    onChange={(params) => handleInputChange(col.filterCustomName || col.field, params.value?.[0]?.id as string || '', FilterTypes.Select)}
                                />
                            )}
                        </Block>
                    )
            )}
            <Button size="compact" onClick={onExportCsv}>
                Export
            </Button>
        </Block>
    )
});

const ScrollableTable: React.FC<{
    columns: ColumnConfig[];
    data: any[];
    pinned: boolean;
    handleDragStart: (column: ColumnConfig) => void;
    handleDrop: any
    handleDragOver: (e: React.DragEvent) => void;
}> = ({ columns, data, handleDragStart, handleDrop, handleDragOver }) => (
    <Table
        columns={columns.map((col) => (
            <Block
                key={col.field}
                data-field={col.field}
                draggable
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onDragStart={() => handleDragStart(col)}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{ cursor: "move" }}

            >
                {col.headerName}
                <Block
                    style={{ cursor: 'pointer', fontSize: '12px', color: '#0070f3' }}
                />
            </Block>
        ))}
        data={data.map((row) =>
            columns.map((col) => (col.render ? col.render(row) : row[col.field]))
        )}
        overrides={{
            TableBodyRow: { style: { height: '40px' } },
            TableBodyCell: {
                style: {
                    fontSize: '14px',
                    padding: '6px 12px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                },
            },
            TableHeadCell: { style: { fontSize: '14px', padding: '6px 12px' } },
        }}
    />
);

const saveConfigToLocalStorage = (key: string, config: ColumnConfig) => {
    try {
        localStorage.setItem(key, JSON.stringify(config));
    } catch (error) {
        console.error('Error saving table configuration to localStorage:', error);
    }
};

const loadConfigFromLocalStorage = (key: string) => {
    try {
        const config = localStorage.getItem(key);
        return config ? JSON.parse(config) : null;
    } catch (error) {
        console.error('Error loading table configuration from localStorage:', error);
        return null;
    }
};

const DataTable = <T,>({
    apiEndpoint,
    initialColumnsConfig,
    defaultFilters = {},
    loading = false,
}: Props): JSX.Element => {
    const [tableState, setTableState] = useState<TableState>(() => {
        const savedConfig = loadConfigFromLocalStorage(window.location.pathname);
        return savedConfig?.version === 1 ? savedConfig : {
            version: 1,
            rowData: [],
            totalRecords: 0,
            pageNumber: 1,
            pageSize: PAGE_SIZES[0],
            filters: defaultFilters,
            pinnedColumns: initialColumnsConfig.filter((col) => col.pinned || col.field === "default"),
            scrollableColumns: initialColumnsConfig.filter((col) => !col.pinned && col.field !== "default"),
            draggedColumn: null as ColumnConfig | null,
            pinnedWidth: 300,
            sortConfig: { sortField: null, sortOrder: null },
        };
    });
    const [queryString, setQueryString] = useState(buildQueryString({
        pageNumber: tableState.pageNumber,
        pageSize: tableState.pageSize,
        ...tableState.filters,
        ...tableState.sortConfig
    }))

    const scrollableRefLeft = useRef<HTMLDivElement>(null);
    const scrollableRefRight = useRef<HTMLDivElement>(null);

    const { data: apiResponse, isLoading: isFetching } = useGet<ApiResponse<T>>(`${apiEndpoint}?${queryString}`, {});
    const { mutate: exportCsv, isPending } = useExport(`${apiEndpoint}?${queryString}`);

    useEffect(() => {
        if (apiResponse) {
            const total = apiResponse?.paginationData?.total || 0;
            const defaultPageNumber = total  ? {} : { pageNumber: 1 };  // Reset to 1st page if no data for current page
            setTableState((prev) => ({
                ...prev,
                rowData: apiResponse?.data || [],
                ...defaultPageNumber, 
                totalRecords: apiResponse?.paginationData?.total || 0,
            }));
        }
    }, [apiResponse]);

    const debouncedFilterChange = useDebounce((newFilters: Record<string, string>) => {
        setQueryString(buildQueryString({
            pageNumber: tableState.pageNumber,
            pageSize: tableState.pageSize,
            ...newFilters,
            ...tableState.sortConfig,
        }));
    }, 500);

    const handleFilterChange = (key: string, value: string, type: FilterTypes) => {
        setTableState((prev) => {
            const updatedFilters = { ...prev.filters, [key]: value };
            if (type === FilterTypes.Text) {
                debouncedFilterChange(updatedFilters);
            } else {
                setQueryString(buildQueryString({
                    pageNumber: prev.pageNumber,
                    pageSize: prev.pageSize,
                    ...updatedFilters,
                    ...prev.sortConfig,
                }));
            }
            return {
                ...prev,
                filters: updatedFilters,
            };
        });
    };

    const handleExportCsv = () => exportCsv();

    const handlePageSizeChange = (size: number) => {
        setTableState((prev) => ({ ...prev, pageSize: size, pageNumber: 1 }));
    };

    const handlePageChange = (nextPage: number) => {
        setTableState((prev) => ({ ...prev, pageNumber: nextPage }));
    };

    const handleDragStart = (column: ColumnConfig) => {
        setTableState((prev) => ({ ...prev, draggedColumn: column }));
    };

    const handleDrop = (e: React.DragEvent, isPinned: boolean) => {
        e.preventDefault();

        const draggedColumn: ColumnConfig = tableState.draggedColumn as ColumnConfig;
        const targetField = e.currentTarget.getAttribute("data-field");
        const targetColumn = [...tableState.pinnedColumns, ...tableState.scrollableColumns].find(
            (col) => col.field === targetField
        );

        const { pinnedColumns, scrollableColumns } = tableState;

        const isDraggedPinned = pinnedColumns.includes(draggedColumn);

        let updatedPinned = [...pinnedColumns];
        let updatedScrollable = [...scrollableColumns];

        if (isDraggedPinned) {
            updatedPinned = pinnedColumns.filter((col) => col !== draggedColumn);
        } else {
            updatedScrollable = scrollableColumns.filter((col) => col !== draggedColumn);
        }

        if (isPinned) {
            const targetIndex = targetColumn
                ? updatedPinned.findIndex((col) => col === targetColumn)
                : updatedPinned.length;
            updatedPinned.splice(targetIndex + 1, 0, draggedColumn);
        } else {
            const targetIndex = targetColumn
                ? updatedScrollable.findIndex((col) => col === targetColumn)
                : updatedScrollable.length;
            updatedScrollable.splice(targetIndex + 1, 0, draggedColumn);
        }

        setTableState((prev) => ({
            ...prev,
            pinnedColumns: updatedPinned,
            scrollableColumns: updatedScrollable,
            draggedColumn: null,
        }));
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const { pinnedColumns, scrollableColumns, rowData, totalRecords, pageNumber, pageSize, pinnedWidth } = tableState;

    const syncScroll = (
        sourceRef: React.RefObject<HTMLDivElement>,
        targetRef: React.RefObject<HTMLDivElement>
    ) => {
        if (sourceRef.current && targetRef.current) {
            targetRef.current.scrollLeft = sourceRef.current.scrollLeft;
        }
    };

    const handleResizePinned = (e: MouseEvent) => {
        const newPinnedWidth = e.clientX;
        setTableState((prev: TableState) => ({ ...prev, pinnedWidth: newPinnedWidth }));
    }

    useEffect(() => {
        const configToSave = {
            filters: tableState.filters,
            sortConfig: tableState.sortConfig,
            pinnedColumns: tableState.pinnedColumns,
            scrollableColumns: tableState.scrollableColumns,
            pageNumber: tableState.pageNumber,
            pageSize: tableState.pageSize,
            rowData: tableState.rowData || [],
            pinnedWidth: tableState.pinnedWidth
        };
        saveConfigToLocalStorage(window.location.pathname, configToSave as any);
        setQueryString(buildQueryString({
            pageNumber: tableState.pageNumber,
            pageSize: tableState.pageSize,
            ...tableState.filters,
            ...tableState.sortConfig,
        }));
    }, [
        tableState.filters,
        tableState.sortConfig,
        tableState.pinnedColumns,
        tableState.scrollableColumns,
        tableState.pageNumber,
        tableState.pageSize,
        tableState.pinnedWidth
    ]);

    useEffect(() => {
        const savedConfig = loadConfigFromLocalStorage(window.location.pathname);
        setTableState((prev) => {
            const mergeColumns = (initialCols: ColumnConfig[], savedCols: ColumnConfig[] = []) => {
                const merged = savedCols.length
                    ? savedCols.map((savedCol) => {
                        const initialCol = initialCols.find((col) => col.field === savedCol.field);
                        return { ...initialCol, ...savedCol };
                    })
                    : initialCols;

                return merged.filter(
                    (col, index, self) =>
                        self.findIndex((c) => c.field === col.field) === index
                );
            };
            return {
                ...prev,
                pinnedColumns: mergeColumns(
                    initialColumnsConfig.filter((col) => col.pinned),
                    savedConfig?.pinnedColumns
                ),
                scrollableColumns: mergeColumns(
                    initialColumnsConfig.filter((col) => !col.pinned),
                    savedConfig?.scrollableColumns
                ),
                filters: savedConfig?.filters || defaultFilters,
            };
        });
    }, [initialColumnsConfig]);

    const tableLoading = isFetching || isPending || loading;

    return (
        <Block padding="16px" backgroundColor="#fff" style={{ borderRadius: '8px' }}>
            <Filters
                columns={[...pinnedColumns, ...scrollableColumns]}
                filters={tableState.filters}
                onFilterChange={(key, value, filterType) => handleFilterChange(key, value, filterType as FilterTypes)}
                onExportCsv={handleExportCsv}
            />
            {tableLoading ? (
                <CenteredDisplay>
                    <Spinner />
                </CenteredDisplay>
            ) : rowData.length === 0 ? (
                <CenteredDisplay>No data found.</CenteredDisplay>
            ) : (
                <Block display="flex" overflow="hidden" >
                    <Block
                        ref={scrollableRefLeft}
                        style={{
                            flex: `0 0 ${pinnedWidth}px`,
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            borderRight: '1px solid #ddd',
                            position: 'relative',
                        }}
                        onScroll={() => syncScroll(scrollableRefLeft, scrollableRefRight)}
                    >
                        {pinnedColumns.length === 0 ? (
                            <Block
                                onDragOver={(e: React.DragEvent) => e.preventDefault()}
                                onDrop={(e: React.DragEvent) => handleDrop(e, true)}
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px dashed #ccc",
                                    backgroundColor: "#f9f9f9",
                                    cursor: "pointer",
                                }}
                            >
                                Drag columns here to pin
                            </Block>
                        ) : (
                            <ScrollableTable
                                columns={pinnedColumns}
                                data={rowData}
                                pinned
                                handleDragStart={(col: ColumnConfig) => handleDragStart(col)}
                                handleDrop={(targetColumn: React.DragEvent) => handleDrop(targetColumn, true)}
                                handleDragOver={handleDragOver}
                            />)}
                        <Block
                            position="absolute"
                            top="0"
                            right="0"
                            bottom="0"
                            width="5px"
                            backgroundColor="#ccc"
                            style={{ cursor: "ew-resize" }}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                window.addEventListener("mousemove", handleResizePinned);
                                window.addEventListener("mouseup", () => {
                                    window.removeEventListener("mousemove", handleResizePinned);
                                    window.removeEventListener("mouseup", handleResizePinned);
                                });
                            }}
                        />
                    </Block>
                    <Block
                        ref={scrollableRefRight}
                        flex="1"
                        overflow="auto"
                        whiteSpace="nowrap"
                    >
                        <ScrollableTable
                            columns={scrollableColumns}
                            data={rowData}
                            pinned={false}
                            handleDragStart={(col: ColumnConfig) => handleDragStart(col)}
                            handleDrop={(targetColumn: React.DragEvent) => handleDrop(targetColumn, false)}
                            handleDragOver={handleDragOver}
                        />
                    </Block>
                </Block>
            )}
            <Block
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginTop="16px"
                whiteSpace="nowrap"
            >
                <Block display="flex" gridGap="scale800" justifyContent="space-between" alignItems="center">
                    <Block>Page Size:</Block>
                    <Select
                        size="compact"
                        clearable={false}
                        options={PAGE_SIZES.map((size) => ({ id: size, label: `${size}` }))}
                        value={[{ id: pageSize, label: `${pageSize}` }]}
                        onChange={(params) => handlePageSizeChange(Number(params.value?.[0]?.id))}
                    />
                    {totalRecords > 0 && (
                        <Block marginLeft="16px">
                            {`Showing ${Math.min(pageNumber * pageSize, totalRecords)} of ${totalRecords} records`}
                        </Block>
                    )}
                </Block>
                <Pagination
                    numPages={Math.ceil((totalRecords / pageSize) || 1)}
                    currentPage={pageNumber}
                    size="compact"
                    onPageChange={({ nextPage }) => handlePageChange(nextPage)}
                />
            </Block>
        </Block>
    );
};

export default DataTable;
