import { useState, useEffect } from 'react';
import { Table, SIZE, DIVIDER } from "baseui/table-semantic";
import { Spinner } from "baseui/spinner";
import { FlexGrid } from "baseui/flex-grid";
import { Tabs, Tab } from "baseui/tabs-motion";
import { Pagination, SIZE as PaginationSize } from "baseui/pagination";
import { Tag, KIND as TagKind } from "baseui/tag";
import { StatefulPopover as Popover } from 'baseui/popover';
import { useGet } from "@hooks/apiHooks.ts";
import formatDate from '@/utils/formatDate';
import { StyledLink } from 'baseui/link';
import { ParagraphSmall } from 'baseui/typography';
import { useBulkUploadCounts } from '@/hooks/useBulkUploadCountsHooks';
import { Button } from 'baseui/button';

const BulkImportTable = () => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [activeTab, setActiveTab] = useState('all');
    const pageSize = 10;

    // Fetch the main data and status counts
    const { data, isFetching } = useGet(
        `agency-dev-applications/bulk-imports?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${activeTab}`
    );

    const counts = useBulkUploadCounts();

    const {
        data: tableData = [],
        total = pageNumber * pageSize,
    } = data as any || {};

    useEffect(() => {
        if (tableData) {
            setLoading(false);
        }
    }, [tableData]);

    const onPageChange = (nextPage) => {
        const page = Math.min(Math.max(nextPage, 1), Math.ceil(total / pageSize));
        setCurrentPage(page);
        setPageNumber(page);
    };

    const handleTabChange = ({ activeKey }) => {
        setActiveTab(activeKey);
        setPageNumber(1);
        setCurrentPage(1);
    };

    const getColumns = () => [
        "Import ID",
        "Email",
        "Name",
        "Job Role",
        "Processing Status",
        "Uploaded On"
    ];

    const getProcessingTag = (status, failureReason) => {
        let tagKind;
        let statusText;
        if (status?.toLowerCase() === 'completed') {
            tagKind = TagKind.positive;
            statusText = 'Done';
        } else if (status?.toLowerCase() === 'pending') {
            tagKind = TagKind.warning;
            statusText = 'Pending';
        } else if (status?.toLowerCase() === 'error') {
            tagKind = TagKind.negative;
            statusText = 'Failed';
        } else {
            tagKind = TagKind.primary;
            statusText = 'Processing';
        }

        const tagContent = <Tag closeable={false} kind={tagKind}>{statusText}</Tag>;

        if (status?.toLowerCase() === 'error' && failureReason) {
            return (
                <Popover
                    content={() => (
                        <ParagraphSmall padding="scale500" maxWidth="200px" style={{ wordBreak: 'break-word' }}>
                            {failureReason}
                        </ParagraphSmall>
                    )}
                    triggerType="hover"
                    placement="top"
                    showArrow
                >
                    <span>{tagContent}</span>
                </Popover>
            );
        }

        return tagContent;
    };

    const getJobLink = (role) => <StyledLink href={`/job/${role}`}>{role}</StyledLink>;

    const getTableData = useCallback(() => {
        if (!tableData || !tableData.length) return [];
        return tableData.map((item) => ([
            item.id,
            item.email,
            item.developerName || item.name,
            getJobLink(item.jobRole),
            getProcessingTag(item.processingStatus, item.failureReason),
            formatDate(item.createdAt)
        ]));
    }, [tableData]);

    const countKeys = ['all', 'pending', 'processing', 'completed', 'error']
    return (
        <FlexGrid flexDirection="column" gridGap="16px">
            <FlexGrid
                flexGridColumnCount={1}
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
            >
                <Button onClick={() => navigate('/add-bulk-candidates')}>Upload candidates In bulk</Button>
            </FlexGrid>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                {countKeys.map(status => (
                    <Tab
                        key={status}
                        title={`${status.charAt(0).toUpperCase() + status.slice(1)} (${Number(counts[status]) || 0})`}
                    />
                ))}
            </Tabs>
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
                <Table
                    columns={getColumns()}
                    data={getTableData()}
                    size={SIZE.spacious}
                    emptyMessage={<h1>No data</h1>}
                    divider={DIVIDER.grid}
                    isLoading={loading || isFetching}
                    loadingMessage={<Spinner $size='30px' />}
                />
            </div>
            <Pagination
                size={PaginationSize.compact}
                numPages={Math.ceil(counts[activeTab] / pageSize)}
                currentPage={currentPage}
                onPageChange={({ nextPage }) => onPageChange(nextPage)}
            />
        </FlexGrid>
    );
};

export default BulkImportTable;
